.mainWrapper {
    padding-top: 231px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.mainInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

.buttonVoiceWrapper {
    display: flex;
    width: 240px;
    padding: 0 10px;
    margin: 0 auto 20px auto;

    div {
        border-radius: 20px;
        height: 50px;
    }
}

.micButton {
    background: linear-gradient(
        0deg,
        #6fc493 -3.22%,
        #47bea7 124.46%
    ) !important;
}

.animated {
    background: linear-gradient(
        107.2deg,
        rgb(150, 15, 15) 10.6%,
        rgb(247, 0, 0) 91.1%
    ) !important;
    animation: ease pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
